import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  SectionPageTitle,
  BreadCrumb,
} from "../components/Section"
import CallNowSection from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"
import ShoppingPolicy from "../components/Content/ShoppingPolicy"

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      @media (min-width: 1600px) {
        object-position: left center !important;
      }
    }
  }
`
const ShoppingPolicyPage = ({ location }) => {
  return (
    <Layout location={location} >
      <Seo title="Shopping Policy Guide - Understanding Metal Buildings Policies and Guidelines" description="Explore the Coast To Coast Carports shopping policies and ensure a seamless shopping experience. Get information about orders, cancellations, and more with our informative guide." />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Shopping Policy</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="120px" xpb="60px" bgColor="#FAFAFA">
        <BackgroundImage>
          <StaticImage
            src="../images/featured-articles.png"
            alt="featured-articles"
          />
        </BackgroundImage>
        <div className="container">
          <SectionPageTitle textAlign="left">Shopping Policy</SectionPageTitle>          
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container mw-1120"><ShoppingPolicy /></div>
      </Section>
      <CallNowSection />
    </Layout>
  )
}

export default ShoppingPolicyPage
