import React from "react"
import { MarkdownContent } from "../Section"

const ShoppingPolicyContent = () => {
  return (
    <MarkdownContent>
        <h2>Shopping Policy Disclaimer</h2>
        <p>Before making any orders or purchases through the Coast to Coast Carports website (https://www.coast-to-coastcarports.com/), please ensure that you have read and understood the Online Shopping Terms and Conditions outlined below. We reserve the right to change these terms and conditions at any time without prior notice. </p>
        <p>To make sure that you are fully informed about our website policies, please take the time to also read our Website Privacy Policy and our Terms and Conditions related to the use of this website.</p>

        <h2>Customer Service Policy</h2>
        <p>At Coast to Coast Carports, we are dedicated to providing exceptional customer service. We make every effort to ensure that all buildings listed on our website are available and that the pricing is accurate. After placing an order, our team reviews it to ensure its accuracy. In case of any issues, we will contact you promptly.</p>
        <p>The estimated delivery time is between 3-7 weeks, depending on your location within the USA. Please note that this is only an estimate, and actual delivery times may vary depending on the time of year.</p>

        <h2>Total Costs of the Building and Services</h2>
        <p>Total costs, including taxes and handling, are displayed at checkout.</p>

        <h2>Refund/Return Policy</h2>
        <p>Before the delivery of the order, we offer refunds on deposits in case of order cancellation, subject to some terms and conditions. After delivery and acceptance of the building, no refunds or returns will be accepted. If any issues arise after the delivery and acceptance, they will be considered warranty and/or workmanship issues.</p>
    </MarkdownContent>
  )
}

export default ShoppingPolicyContent
